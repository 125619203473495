<template>
  <div class="flex-grow-1">
    <AdminTitle title="My Jobs">
      <template v-slot:actions>
        <div class="d-flex gap-12 align-center">

          <v-btn
            v-if="$store.getters['pricing/withActiveSub']"
            :to="{ name: 'Job' }"
            color="accent gradient"
          >
            Create Job
          </v-btn>

          <MyJobFilter  @changed="updateOrder" />
        </div>
      </template>
    </AdminTitle>

    <!-- Filters -->
    <v-row>
      <v-col cols="12" class="jobs-section">
        <preloader v-if="status.getting" />
        
        <custom-alert v-if="jobs.length == 0 && !status.getting">
          You don't have any job listing yet.
        </custom-alert>
        
        <div v-if="jobs && !status.getting">

          <!-- Job Card -->
            <job-card
              v-for="job in ordered" 
              :key="job.id"
              :job="job"
            />
          <!-- End Job Card -->

        </div>
      </v-col>
    </v-row>
    <!-- End Filters -->

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MyJobFilter from '@/components/dashboard/job/MyJobFilter'
import JobCard from '../JobCard'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'MyJobs',

  metaInfo: {
    title: 'My Jobs'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      orderBy: 'createdAt'
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    MyJobFilter,
    JobCard
  },
   
  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      jobs: (state) => state.jobs.myJobs,
      status: state => state.jobs.status,
      applicantStatus: (state) => state.apply.status,
    }),

    ordered: function () {
      let jobs = this.jobs.filter(job => job.status == 'Closed')

      // ORDER BY CREATED
      if (this.orderBy == 'created') {
        jobs = _.orderBy(jobs, 'createdAt', 'desc')
      }

      // CREATED AT DATE
      if (this.orderBy == 'start_date') {
        jobs = _.sortBy(jobs, [function (job) {
          let range = job.contractDates[0]
          return moment(range[0]).valueOf()
        }])
      }

      // ORDER BY PRIVATE
      if (this.orderBy == 'private') {
        jobs = jobs.filter(job => job.private)
      }
      
      // ORDER BY PRIVATE
      if (this.orderBy == 'public') {
        jobs = jobs.filter(job => !job.private)
      }

      return jobs
    }
  },


  methods: {
    ...mapActions("jobs", [
      'getCreatedJobs',
    ]),

    ...mapActions("apply", [
      'allApplicants'
    ]),

    updateOrder(order) {
      this.orderBy = order
    }
  },
  
  created() {
    this.allApplicants()
    this.getCreatedJobs()
    this.$store.commit('job/duplicatingState', null)
  }
}
</script>