<template>
  <v-menu
    :close-on-content-click="false"
    offset-y
    bottom
    left
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" class="rounded" icon outlined>
        <v-icon>mdi-sort</v-icon>
      </v-btn>  
    </template>

    <v-card>
      <v-card-text>
        <v-radio-group
          v-model="order"
          @change="$emit('changed', $event)"
          class="mt-0" 
          hide-details 
        >
          <v-radio label="Date Created" value="created"></v-radio>
          <v-radio label="Contract Start Dart" value="start_date"></v-radio>
          <v-radio label="Private" value="private"></v-radio>
          <v-radio label="Public" value="public"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>

export default {
  data() {
    return {
      order: 'created',
    }
  },
}
</script>
